<template>
  <div class="info_box">
    <div class="info_top" v-if="information && information.length > 0">
      <div class="top_left">
        <img src="../../../../assets/image/Group 1142814917.png" />
        <span>接待人信息（{{ information.length }}）</span>
      </div>
      <!-- 右边搜索 -->
      <!-- <div class="search_right">
        <el-input
          v-model="searchVal"
          @clear="search"
          placeholder="请输入姓名、手机号或职位"
          clearable
        >
          <el-button slot="append" type="primary" @click="search">搜索</el-button>
        </el-input>
      </div> -->
    </div>
    <div v-if="information && information.length > 0">
      <div class="info_content" v-for="(item, index) in information" :key="index">
        <el-descriptions class="margin-top" :column="3" border>
          <el-descriptions-item >
            <template slot="label">接待人姓名</template>
            {{ item.name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">接待人手机号</template>
            {{ item.mobile }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">接待人职位</template>
            {{ item.position }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <el-empty :image="require('@/assets/image/Group (10).png')" description="暂无内容" :image-size="340" v-else></el-empty>
  </div>
</template>
<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    information: {
      type: Array,
      default: () => []
    }
  },
  data() {
    //这里存放数据
    return {
      searchVal: ''
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    search() {
      this.$emit('searchData', this.searchVal, 'one')
    }
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {}
}
</script>
<style scoped lang="less">
::v-deep .el-descriptions-item__cell {
  font-size: 16px;
}
::v-deep  .el-descriptions__body .el-descriptions__table .el-descriptions-item__cell {
  text-align: center;
  color: #333333;
  padding: 20px;
  width: 450px;
}
::v-deep .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
  padding-bottom: normal;
}
::v-deep .el-descriptions-item__label {
  width: 10%;
  font-weight: 550 !important;
}
.info_box {
  width: 100%;
  height: 100%;
}
.info_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  .top_left {
    display: flex;
    font-size: 18px;
    color: #4e93fb;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }
}
.info_content {
  margin-bottom: 10px;
}
.search_right {
  width: 460px;
  /deep/ .el-button {
    border: 1px solid #448aff;
    color: #fff;
    border-radius: 0;
    background-color: #448aff;
  }
}
</style>
