<template>
  <div>
    <div class="card_list">
      <div class="card_item">
        <div class="card_top color_green" v-if="form.visitWay == '线上会议'">
          {{ form.visitWay }}
        </div>
        <div class="card_top color_blue" v-else-if="form.visitWay == '线下走访'">
          {{ form.visitWay }}
        </div>
        <div class="card_top color_purple" v-else-if="form.visitWay == '电话沟通'">
          {{ form.visitWay }}
        </div>
        <div class="card_left">
          <div class="img_box">
            <img v-if="form.companyLogo" :src="form.companyLogo" alt="" />
            <img v-else src="../../../../assets/image/enterprise-default-logo.png" alt="" />
          </div>
          <div class="info_box">
            <div class="row">
              <div class="enterprise_name">
                {{ form.companyFullName }}
              </div>
            </div>
            <div class="row">
              <div
                class="type_tag"
                v-for="(item, index) in newIndustryLabel(form.industryLabel).slice(0, 2)"
                :key="index"
              >
                {{ item }}
              </div>
              <el-popover placement="right" width="400" trigger="hover">
                <div class="row_tag">
                  <div
                    class="type_tag1"
                    v-for="(lo, lh) in newIndustryLabel(form.industryLabel).slice(2)"
                    :key="lh"
                  >
                    {{ lo }}
                  </div>
                </div>
                <div
                  class="type_tag"
                  slot="reference"
                  v-show="newIndustryLabel(form.industryLabel).length > 2"
                >
                  {{ newIndustryLabel(form.industryLabel).length - 2 }}+
                </div>
              </el-popover>
            </div>
            <div class="row">
              <div>
                <span>{{ form.district || '-' }}</span>
              </div>
              <span class="bottom_time">走访时间：{{ form.visitTime || '-' }}</span>
            </div>
          </div>
        </div>
        <div class="card_right">
          <div class="box_list">
            <div class="item">
              <div class="row">
                <span class="txt">接待人</span>
              </div>
              <div class="num" v-if="form.receiverInfo">有</div>
              <div class="num1" v-else>无</div>
            </div>
            <div class="item">
              <div class="row">
                <span class="txt">参与人</span>
              </div>
              <div class="num" v-if="form.participantInfo">有</div>
              <div class="num1" v-else>无</div>
            </div>
            <div class="item">
              <div class="row">
                <span class="txt">需求信息</span>
              </div>
              <div class="num" v-if="form.requirementDescribe">有</div>
              <div class="num1" v-else>无</div>
            </div>
            <div class="item">
              <div class="row">
                <span class="txt">解决方案</span>
              </div>
              <div class="num" v-if="form.solutionFile">有</div>
              <div class="num1" v-else>无</div>
            </div>
          </div>
          <div class="btn_box">
            <div class="set_manager">
              <span class="manager_name"> 客户经理： </span>
              <div class="middle_icon_box">
                <img v-if="form.customerHead" :src="form.customerHead" alt="" />
                <img v-else src="@/assets/image/wait-set-manager.png" alt="" />
              </div>
              <span class="manager_name">
                {{ form.customerName }}
              </span>
            </div>
            <div class="btn_list">
              <el-button type="primary" @click="toBack">返回一上级</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card_conter">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane
          :label="`接待人（${
            form.visitReceiverResultList ? form.visitReceiverResultList.length : 0
          }）`"
          name="one"
        >
          <ReceptionInfo
            :information.sync="form.visitReceiverResultList"
            @searchData="searchData"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="`参与人（${
            form.visitParticipantResultList ? form.visitParticipantResultList.length : 0
          }）`"
          name="two"
        >
          <ParticipationInfo
            :information.sync="form.visitParticipantResultList"
            @searchData="searchData"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="`需求信息（${form.requirementDescribes ? form.requirementDescribes.length : 0}）`"
          name="three"
        >
          <DemandInfo :information.sync="form" />
        </el-tab-pane>
        <el-tab-pane
          :label="`解决方案（${form.solutionFiles ? form.solutionFiles.length : 0}）`"
          name="four"
        >
          <ResolveInfo :information.sync="form" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import ReceptionInfo from './receptionInfo'
import ParticipationInfo from './participationInfo'
import DemandInfo from './demandInfo'
import ResolveInfo from './resolveInfo'

import { getSelectById } from '@/api/visiting.js'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { ReceptionInfo, ParticipationInfo, DemandInfo, ResolveInfo },
  data() {
    //这里存放数据
    return {
      form: {},
      activeName: 'one'
    }
  },
  //监听属性 类似于data概念
  computed: {
    type() {
      return this.$route.query.type
    },
    id() {
      return this.$route.query.id
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async search() {
      const res = await getSelectById({ id: this.id })
      if (res.resultCode === 200) {
        this.form = res.data
       if( this.form.visitReceiverResultList){
        let list=[]
        this.form.visitReceiverResultList.forEach(el=>{
          if(el.name){
            list.push(el)
          }
        })
        this.form.visitReceiverResultList=list
       }
        if(this.form.requirementFiles){
          let list=[]
          this.form.requirementFiles.forEach(el => {
            list.push(
              {
                url:el,
                name:this.getLastPart(el)
              }
            )
          });
          this.$set(this.form,'files',list)
        }
        
        if(this.form.solutionFiles){
          let list=[]
          this.form.solutionFiles.forEach(el => {
            list.push(
              {
                url:el,
                name:this.getLastPart(el)
              }
            )
          });
          this.$set(this.form,'solutions',list)
        }
        
        
        this.newData(res.data)
      } else {
        this.$message.warning('获取企业信息失败!')
      }
    },
    getLastPart(str) {
      // 使用split方法以'/'为分隔符分割字符串，生成数组
      var parts = str.split("/");
      // 使用pop方法获取数组的最后一个元素，即'/'后的数据
      return parts.pop();
    },
    // 整体数据处理
    newData(val) {
      // 接待人信息
      if (!val.visitReceiverResultList) {
        this.form.visitReceiverResultList = []
      }
      // 参与人信息
      if (!val.visitParticipantResultList) {
        this.form.visitParticipantResultList = []
      }
      // 需求信息
      if (!val.requirementDescribes) {
        this.form.requirementDescribes = []
      }
      // // 解决方案
      if (!val.solutionFiles) {
        this.form.solutionFiles = []
      }
    },
    // 数据处理
    newIndustryLabel(val) {
      if (!val) {
        return []
      } else {
        return val.split(',')
      }
    },
    // 点击搜索
    searchData(val, type) {
      switch (type) {
        case 'one':
          if (!val) {
            this.search()
          } else {
            // 筛选函数
            const resultArray = this.form.visitReceiverResultList.filter((item) => {
              // 检查对象的属性值是否等于 val
              return Object.values(item).some((value) => value == val)
            })
            this.form.visitReceiverResultList = resultArray
          }
          break
        case 'two':
          if (!val) {
            this.search()
          } else {
            // 筛选函数
            const resultArray = this.form.visitParticipantResultList.filter((item) => {
              // 检查对象的属性值是否等于 val
              return Object.values(item).some((value) => value == val)
            })
            this.form.visitParticipantResultList = resultArray
          }
          break
        case 'three':
          if (!val) {
            this.search()
          } else {
            // 筛选函数
            const filteredArray = this.form.requirementDescribes.filter((item) => item == val)

            this.form.requirementDescribes = filteredArray
          }

          break
        default:
          break
      }
    },
    // 返回上级
    toBack() {
      this.$router.go(-1)
    }
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {
    this.activeName = this.type
    this.search()
  },
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {}
}
</script>
<style scoped lang="less">
::v-deep .el-tabs__nav-scroll {
  background-color: #fafafa;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
  border: none;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none;
}
::v-deep .el-tabs__item {
  font-size: 18px;
}
::v-deep .el-tabs__item.is-active {
  background: #ffffff;
}
::v-deep .el-tabs__content {
  padding: 0 16px;
  height: 560px;
  overflow-y: auto;
  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
}
.card_list {
  display: flex;
  flex-flow: column;
  width: 100%;
  .po_reportingStatus {
    position: absolute;
    color: #f53939;
    top: 50%;
    transform: translateY(-50%);
    left: 28%;
    z-index: 999 !important;
    img {
      width: 76px;
      height: 60px;
    }
  }
  .card_item {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #fff;
    margin-bottom: 10px;
    padding: 10px 20px;
    box-sizing: border-box;
    position: relative;
    .card_top {
      position: absolute;
      top: 10px;
      left: 6px;
      height: 30px;
      min-width: 84px;
      color: #ffffff;
      z-index: 99999;
      text-align: center;
      line-height: 30px;
      border-radius: 15px 20px 20px 0;
    }
    .card_left {
      display: flex;
      align-items: center;
      padding-top: 10px;
      box-sizing: border-box;
      width: 45%;
      margin-right: 20px;
      .item_checkbox {
        line-height: 36px;
        margin: 0px 10px;
      }
      .img_box {
        width: 100px;
        height: 100px;
        margin-right: 10px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        img {
          width: 100px;
          height: 100px;
        }
        .tag_box {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 20px;
          color: #fff;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          position: absolute;
          top: 10px;
          left: 0;
          z-index: 999;
        }
      }
      .info_box {
        display: flex;
        justify-content: center;
        flex-flow: column;
        .bottom_time {
          margin-left: 20px;
        }
        .enterprise_name {
          cursor: pointer;
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          color: #000;
          max-width: 340px; /* 固定宽度超出隐藏 */
          overflow: hidden; /*  超出隐藏 */
          text-overflow: ellipsis; /*  超出以 ...显示 */
          display: -webkit-box;
          -webkit-line-clamp: 1; /* // 显示1行 */
          -webkit-box-orient: vertical;
        }

        .tag_list {
          display: flex;
          .tag_item {
            font-size: 10px;
            color: #4e93fb;
            border: #559eff 1px solid;
            border-radius: 4px;
            padding: 5px;
            margin-right: 6px;
          }

          .show_more {
            position: relative;
            font-size: 14px;
            line-height: 26px;
            text-align: center;
            cursor: pointer;
            .show_tag_box {
              position: absolute;
              top: 100%;
              left: 100%;
              z-index: 99;
              width: 240px;
              padding: 6px;
              box-sizing: border-box;
              border-radius: 4px;
              background-color: #fff;
              border: #f8f8f8 1px solid;
              display: flex;
              flex-flow: row wrap;
              li {
                padding: 3px 5px;
                .tag {
                  width: fit-content;
                  font-size: 10px;
                  line-height: 20px;
                  color: #4e93fb;
                  border: #559eff 1px solid;
                  border-radius: 4px;
                  padding: 0 6px;
                  box-sizing: border-box;
                }
              }
            }
          }
        }
      }
    }
    .card_right {
      flex: 1;
      display: flex;
      .box_list {
        display: flex;
        align-items: center;
        .item {
          display: flex;
          justify-content: center;
          text-align: center;
          margin-right: 30px;
          .num {
            cursor: pointer;
            font-weight: bold;
            font-size: 24px;
            color: #4e93fb;
            margin-bottom: 10px;
          }
          .num1 {
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            margin-bottom: 10px;
          }
          .txt {
            font-size: 16px;
            margin-right: 15px;
            color: #151515;
          }
        }
        // .item:hover {
        //   .num {
        //     color: #448aff;
        //   }
        //   .icon_box {
        //     color: #448aff;
        //   }
        //   span {
        //     color: #448aff;
        //   }
        // }
      }
      .btn_box {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 10px;

        .divs {
          margin-right: 10px;
        }
        .set_manager {
          display: flex;
          align-items: center;
          min-width: 120px;
          margin-right: 40px;
          .middle_icon_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: #f4f4f4;
            overflow: hidden;
            margin-right: 10px;
            img {
              width: 35px;
              height: 35px;
            }
            i {
              font-size: 16px;
              color: #c9c9c9;
            }
          }
          .manager_name,
          .wait_set {
            cursor: pointer;
            font-size: 16px;
          }
        }
        .btn_list span {
          font-size: 14px;
        }
      }
    }
  }
}
.card_conter {
  background-color: #fff;
  width: 100%;
  height: calc(100vh - 240px);
}
.color_green {
  background-color: #0ecbc6;
}
.color_blue {
  background-color: rgb(68, 138, 255);
}
.color_purple {
  background-color: #6a70ff;
}
.color_gray {
  background-color: #a1a1a1;
}
.type_tag {
  font-size: 14px;
  color: #559eff;
  // text-align: center;
  margin-right: 10px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #559eff;
}
.type_tag1 {
  font-size: 14px;
  color: #559eff;
  // text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #559eff;
}
.row_tag {
  display: flex;
  flex-wrap: wrap; /* 允许换行 */
}
</style>
